<template>
  <v-item-group>
    <v-container class="pt-10">
      <v-row>
        <v-col v-for="n in lectures" :key="n.subjectGuid" cols="12" lg="4" md="6" sm="12">
          <v-card
            class="d-flex align-center rounded-xl"
            height="100"
            @click="openLec(n.subjectGuid)"
            outlined
          >
            <v-scroll-y-transition>
              <v-list-item three-line>
                <v-list-item-content>
                  <v-list-item-title>
                    {{ n.subjectName }}
                  </v-list-item-title>

                  <v-list-item-subtitle v-if="isInRole(2)">
                    {{ n.departments[0].departmentName }}
                  </v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-avatar tile size="80">
                  <img src="../../assets/lec.svg" />
                </v-list-item-avatar>
              </v-list-item>
            </v-scroll-y-transition>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-item-group>
</template>

<script>
export default {
  data() {
    return {
      lectures: [],
      overlay: false
    };
  },
  watch: {
    // overlay(val) {
    //   val &&
    //     setTimeout(() => {
    //       this.overlay = false;
    //     }, 3000);
    // },
  },
  methods: {
    openLec(id) {
      // console.log(id)
      this.$router.push({ path: `lecOne/${id}`, params: { id: id } });
    },
    filterDuplicates(items) {
      return items.filter(
        (thing, index, self) => index === self.findIndex(t => t.subjectGuid === thing.subjectGuid)
      );
    },

    getLec() {
      this.overlay = true;
      this.axios
        .get("Subjects/Get")
        .then(res => {
          if (this.isInRole(2)) {
            this.lectures = res.data.data.subjects;
          } else {
            this.lectures = this.filterDuplicates(res.data.data);
          }
        })
        .catch(err => {})
        .finally(() => {
          this.overlay = false;
        });
    }
  },

  created() {
    this.getLec();
  }
};
</script>

<style></style>
